<template>
  <div>
    <div>
      <div class="right_header">
        <Row :gutter="16">
          <Col span="6">
            <span>专利申请号、专利名称或申请人</span>
            <Input v-model="params.patentNo"/>
          </Col>
          <Col span="4">
            <span>回复状态</span>
            <Select v-model="params.state" :transfer="transfer">
              <Option value="">全部</Option>
              <Option value="0">未回复</Option>
              <Option value="1">已回复</Option>
            </Select>
          </Col>

          <Row :gutter="8">
            <Col span="6">
              <div class="button_style" style="position: relative;top: 50%" @click="search()">查询</div>
            </Col>
            <Col span="6">
              <div class="button_style"
                   style="margin-left: 16px;position: relative;top: 50%;background-color: #fda736" @click="exportReport()">导出
              </div>
            </Col>
            <Col span="8">
              <div class="button_style"
                   style="margin-left:26px;width: 100px;position: relative;top: 50%;background-color: #33c26c"
                   @click="sellModal=true">新增出售专利
              </div>
            </Col>
          </Row>


        </Row>
      </div>
      <div class="right_real_content">
        <Table border :columns="columns12" :data="data6" :disabled-hover="true">
          <template slot-scope="{ row }" slot="name">
            <strong>{{ row.name }}</strong>
          </template>
          <template slot-scope="{ row, list }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px;color: rgb(54, 194, 207);"
                    @click="deleteFun(row)">删除
            </Button>
            <span class="operation_span" v-if="row.state === '已回复'">|</span>
            <Button type="error" size="small" @click="replayContent(row)" style="color: rgb(54, 194, 207);"
                    v-if="row.state === '已回复'"> 查看回复
            </Button>
          </template>
        </Table>
        <Page :total="total" show-elevator style="margin-top: 50px;float: right" @on-change="changePage"/>
      </div>
    </div>
    <!--回复内容-->
    <Modal
      id="replayContent"
      v-model="replayModal"
      title="回复内容"
      width="800"
      :footer-hide="footHide"
    >
      <div style="margin-bottom: 50px">
        <div style="padding: 20px;">
          <div style="width: 95%;margin-left: 50%;transform: translateX(-50%);margin-top: 20px;margin-bottom: 30px">
            {{replay}}
          </div>
        </div>
      </div>
    </Modal>
    <!--出售专利-->
    <Modal
      v-model="sellModal"
      title="新增出售专利"
      width="800"
      :footer-hide="footHide"
    >
      <div style="margin-bottom: 50px">
        <div style="padding: 20px;">
          <div style="width: 95%;margin-left: 50%;transform: translateX(-50%);margin-top: 20px;margin-bottom: 30px">
            <Form :model="sellForm" :label-width="100">
              <FormItem label="专利申请号">
                <Input v-model="sellForm.patentNo" placeholder="请输入专利申请号"></Input>
              </FormItem>
              <FormItem label="专利名称">
                <Input v-model="sellForm.patentTi" placeholder="请输入专利名称"></Input>
              </FormItem>
              <FormItem label="出售价格">
                <Input v-model="sellForm.price" placeholder="请输入出售价格"></Input>
              </FormItem>
              <FormItem label="联系人">
                <Input v-model="sellForm.contactMan" placeholder="请输入联系人"></Input>
              </FormItem>
              <FormItem label="联系电话">
                <Input v-model="sellForm.contactNo" placeholder="请输入联系电话"></Input>
              </FormItem>
            </Form>
          </div>
          <div style="margin-left: 50px">
            <div class="button_style" style="margin-left: 26px;width:80px;position: relative;top: 50%"
                 @click="confirmAdd()">提交
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!--删除专利-->
    <Modal
      v-model="deleteModal"
      title="删除出售专利"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px;">
          <div style="width: 95%;margin-left: 50%;transform: translateX(-50%);margin-top: 20px;text-align: center">
            <div style="margin-bottom: 30px"><span>您确定该专利不再寄售？</span></div>
            <div>
              <div class="button_style" style="margin-left: 26px;width:80px;position: relative;top: 50%"
                   @click="deleteModal=false;">我再想想
              </div>
              <div class="button_style"
                   style="margin-left: 26px;width:80px;position: relative;top: 50%;background-color: #fda736"
                   @click="confirmDel()">确定
              </div>
            </div>
          </div>

        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {getSellingList, deleteSelling, saveSelling} from "../../../plugins/api/patentTrustee";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
  errorToast
} from "@/plugins/tools/util";
import axios from "axios";
import {patentUrl} from "../../../plugins/http/baseUrl";
import {getLocalToken} from "../../../plugins/tools/storage";

export default {
  name: "SalePatent",
  mounted() {
    this.getSelling();
  },
  data() {
    return {
      transfer:true,
      total:10,
      replay:'',
      footHide:false,
      params: {
        total: 10,
        patentNo: "", //专利号、
        state: "",  //回复方式
        pageNum: 1,
        pageSize: 10
      },
      deleteItem:{},
      replayModal: false,
      deleteModal: false,
      sellModal: false,
      numberOrName: '',
      replayType: [],
      sellForm: {
        contactMan: "",
        contactNo: "",
        price: "",
        patentTi: '',
        patentNo: ""
      },
      data6: [],
      columns12: [
        {
          title: '专利申请号',
          key: 'patentNo',
          align: 'center'
        },
        {
          title: '专利名称',
          key: 'patentTi',
          align: 'center'
        },
        {
          title: '出售价格',
          key: 'price',
          align: 'center'
        },
        {
          title: '联系人',
          key: 'contactMan',
          align: 'center'
        },
        {
          title: '联系方式',
          key: 'contactNo',
          align: 'center'
        },
        {
          title: '回复状态',
          key: 'state',
          align: 'center'
        },
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        }
      ],
    }
  },
  methods: {

    // 查询
    async search() {
      this.params.pageNum = 1;
      this.getSelling();
    },

    // 翻页
    async changePage(index) {
      this.params.pageNum = index;
      this.getSelling();
    },
    async exportReport(){
      let json = await axios({
        method: 'post',
        url: `${patentUrl}/patent/sellingExport`,
        data: this.params,
        responseType: 'arraybuffer',
        headers: {'token': getLocalToken()}
      })
      if(json.status == 200){
        let fileName = "报告.xls";
        let blob = new Blob([json.data], {type: 'application/vnd.ms-excel};charset=utf-8'});
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href)
      }else{
        warnToast(json.message)
      }

    },
    // 查看回复
    replayContent(row){
      this.replayModal = true;
      this.replay = row.processDesc || '';
    },
    // 得到列表
    async getSelling() {
      let json = await getSellingList(this.params);
      if (json.code == 0) {
        this.data6 = json.data.list;

        this.data6.forEach((item, index) => {
          if (item.state == 0) {
            item.state = '未回复'
          } else {
            item.state = '已回复'
          }
        })
        this.total = json.data.total;
      } else {
        errorToast(json.message)
      }
      // this.$parent.changeHeight();
    },
    deleteFun(item) {
      this.deleteModal = true;
      this.deleteItem = item
    },

    // 添加
    async confirmAdd() {
      for(let key in this.sellForm){
        if(this.sellForm[key] === ''){
          warnToast("请将信息填写完整")
          return ;
        }
      }
      if(!isPhoneNumber(this.sellForm["contactNo"])){
        warnToast("请填写正确的号码")
        return ;
      }
      let json = await saveSelling(this.sellForm)
        if(json.code == 0){
          successToast("提交成功")
          this.sellModal = false;
            this.getSelling()
        }else{
          errorToast(json.message)
        }
      this.sellModal = false;
    },

    // 确定删除
    async confirmDel() {
      let data = {
        patentId:this.deleteItem.id
      }
      let json = await deleteSelling(data)
      if(json.code == 0){
        this.getSelling();
        successToast("删除成功")
      }else {
        errorToast(json.message)
      }
      this.deleteModal = false;
    },
  }
}
</script>

<style scoped lang="scss">
/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: #f3fcfc !important;
}

 /deep/ td {
  border-right: none !important;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  overflow: hidden;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;

  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
  border-radius: 2px;
  background-color: rgb(54, 194, 207);
  width: 80px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  padding: 0 5px;
  box-sizing: border-box;
}

.input_search, .input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;

}
</style>
