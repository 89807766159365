import {patentUrl} from "../http/baseUrl";
import {get, post, del, put,streamPost} from "../http/http";


// 监控列表
export const getClaimPatentList = (params) => {
  return get(`${patentUrl}/monitor/claimPatentList`, params)
}

// 添加监控
export const monitorAdd = (data) => {
  return post(`${patentUrl}/monitor/add`,data)
}

// 专利托管列表
export const getMonitorList = (params) => {
  return get(`${patentUrl}/monitor/list`, params)
}

// 出售专利
export const saveBatchSelling = (data) => {
  return post(`${patentUrl}/monitor/saveBatchSelling`,data)
}

// 导出监控专利
export const monitorExport = (data) => {
  return streamPost(`${patentUrl}/monitor/export`, data)
}


// 删除监控
export const monitorUpdate = (params) => {
  return put(`${patentUrl}/monitor/update`, params)
}

//同步官方数据
export const monitorSyncInfo = (params) => {
  return get(`${patentUrl}/monitor/syncInfo`, params)
}

// 费用明细
export const monitorSyncFee = (params) => {
  return post(`${patentUrl}/monitor/syncFee`, params)
}


// 缴费列表

export const getPaylist = (params) => {
  return get(`${patentUrl}/order/list`,params)
}

//取消代缴订单： order/cancelOrder?orderSn
export const cancelPay = (params) => {
  return put(`${patentUrl}/order/cancelOrder`,params)
}

//删除代缴订单： order/deleteOrder?orderSn
export const delPay = (params) => {
  return del(`${patentUrl}/order/deleteOrder`,params)
}


// 去缴费
export const goOrderCal=(data) => {
  return post(`${patentUrl}/order/calculate`,data)
}

// 生成订单
export const createOrder = (data) => {
  return post(`${patentUrl}/order/creat`,data)
}

// 出售专利列表
export const getSellingList = (params) => {
  return get (`${patentUrl}/patent/getSellingList`,params)
}

export const deleteSelling = (params) => {
  return del (`${patentUrl}/patent/deleteSelling`,params)
}

export const saveSelling = (data) => {
  return post (`${patentUrl}/patent/saveSelling`,data)
}


// 求购专利列表
export const getDemandList = (params) => {
  return get (`${patentUrl}/patent/getDemandList`,params)
}

export const deleteDemand = (params) => {
  return del (`${patentUrl}/patent/deleteDemand`,params)
}

export const saveDemand = (data) => {
  return post (`${patentUrl}/patent/saveDemand`,data)
}


export const demandExport = (data) => {
  return streamPost(`${patentUrl}/patent/demandExport`, data)
}

export const nianfeiExport = (data) => {
  return streamPost(`${patentUrl}/order/annuityExport`, data)
}



export const getLawType = (params) => {
  return get(`${patentUrl}/patent/queryPatentAllLsnt`,params)
}
// 上传凭证

export const uploadVoucher =(data) => {
  return put(`${patentUrl}/order/uploadVoucher`,data)
}
